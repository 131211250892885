.wrapper {
  border-radius: var(--radius-20, 20px);

  background: url(../../../../images/wallet/pattern.png), var(--Bg-Light);
  object-fit: cover;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.header,
.infoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.balanceWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.balanceContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.currencyContainer {
  border-radius: 6px;
  background: var(--Bg-Default);
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.currencyName {
  margin-top: 1px;
}
.currencyLogo {
  max-width: 18px;
  width: 100%;
}
.buttonContainer {
  max-width: 304px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.icon {
  max-width: 20px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    padding: 24px;
  }
  .infoContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .infoContainer {
    flex-direction: row;
    align-items: center;
  }
  .buttonContainer {
    max-width: 304px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  .header,
  .infoContainer {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
  }
  .header {
    gap: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .buttonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
  }
  .header {
    gap: 24px;
  }
  .button {
    font-size: 14px;
    gap: 12px;
    padding: 10px 12px;
  }
  .icon {
    max-width: 16px;
  }
}
