.mainWrapper {
  display: grid;
  grid-template-columns: 1fr 348px;
  gap: 60px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.buttonContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr auto;
  gap: 8px;
}

.input {
  background: var(--Bg-Light);
  max-width: 220px;
}

.downloadButton {
  border-radius: 8px;
  background: var(--Bg-Light);
  padding: 9px 10px;
}

.downloadIcon {
  max-width: 24px;
  width: 100%;
}

.tableWrapper {
  overflow-x: auto;
  max-height: 500px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.heading,
.item {
  white-space: nowrap;
  text-align: left;
}
.table td,
.table th {
  height: 100%;
  border-top: 1px solid var(--Br-Dark);
  padding: 10px 20px;
}
.table th {
  position: sticky;
  top: -1px;
  background: var(--Bg-Default);
}
.table th:first-child,
.table td:first-child {
  padding-left: 0;
}
.table th:last-child,
.table td:last-child {
  padding-right: 12px;
}

.firstHeading,
.firstItem {
  padding-left: 10px;
}

.lastHeading {
  padding-right: 10px;
}
.paymentUrl {
  display: flex;
  align-items: center;
  gap: 10px;
}
.url {
  max-width: 186px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.copyIcon {
  max-width: 16px;
}
.link:hover {
  transform: translateY(0);
}
.arrowRight {
  color: var(--Text-Primary);
  font-size: 18px;
  transition: 0.3s;
}
.arrowRight:hover {
  transform: translateY(-2px);
}
.input input::placeholder {
  color: var(--Text-Teritary);
}

@media only screen and (max-width: 1199px) {
  .mainWrapper {
    gap: 40px;
  }

  .header {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .mainWrapper {
    gap: 60px;
  }
}
@media only screen and (max-width: 520px) {
  .header {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .input {
    max-width: 100%;
  }
}
