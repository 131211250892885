.wrapper {
  padding: 28px;
  border: 1px solid var(--Br-Dark);
  border-radius: 16px;
}

.infoContainer {
  padding: 24px 0;
  display: grid;
  grid-template-columns: 240px 1fr minmax(125px, auto);
  gap: 24px 15px;
  align-items: center;
  border-bottom: 1px solid var(--Br-Dark);
}
.infoContainer:last-child {
  border: none;
}
.deviceInfo,
.ipAndLocation {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.deviceTypeAndStatus {
  display: flex;
  align-items: center;
  gap: 8px;
}
.status {
  padding: 2px 6px;
  border-radius: 6px;
}
.online {
  color: var(--Success);
  background: rgba(36, 199, 136, 0.1);
}
.offline {
  background: var(--Bg-Light);
  color: var(--Text-Teritary);
}
.button {
  padding: 10px 14px;
  height: 50px;
}
@media only screen and (max-width: 991px) {
  .infoContainer {
    grid-template-columns: 1fr minmax(125px, auto);
  }
  .deviceInfo {
    grid-column: 1/-1;
  }
}
@media only screen and (max-width: 520px) {
  .infoContainer {
    grid-template-columns: 1fr;
  }
}
