.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px 0;
  padding-top: 30px;
  max-width: 1199px;
  width: 90%;
  margin: 0 auto;
}
.logo {
  max-width: 101px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
