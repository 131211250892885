.modal {
  padding-bottom: 20px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid var(--Br-Dark-2);
  padding: 24px;
  border-radius: 10px;
}
.addressContainer {
  display: grid;
  grid-template-columns: 176px auto;
  align-items: center;
  gap: 28px;
}
.qrCodeContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.addressAndInfo {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.qrCode {
  max-width: 176px;
  width: 100%;
}

.copyAddress {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 28px;
  border: 1px solid var(--Br-Dark-2);
  padding: 4px 12px;
  padding-right: 4px;
  border-radius: var(--radius);
}
.address {
  word-break: break-all;
}
.copyIcon {
  max-width: 20px;
  width: 100%;
}
.copyContainer {
  width: 36px;
  height: 36px;
  background: var(--Primitive-950);
  border-radius: 8px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
.verificationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
  .selectContainer {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .addressContainer {
    grid-template-columns: 1fr;
  }

  .qrCodeContainer {
    align-items: center;
  }
}
