.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
}
.info {
  line-height: 155%;
}
