.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.tableWrapper {
  overflow-x: auto;
  max-height: 700px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  overflow-x: auto;
}

.heading,
.item {
  white-space: nowrap;
  text-align: left;
}
.table td,
.table th {
  height: 100%;
  border-top: 1px solid var(--Br-Dark);
  padding: 10px 20px;
}
.table th {
  position: sticky;
  top: -1px;
  background: var(--Bg-Default);
}
.table th:first-child,
.table td:first-child {
  padding-left: 0;
}
.table th:last-child,
.table td:last-child {
  padding-right: 12px;
}

.firstHeading,
.firstItem {
  padding-left: 10px;
}
.lastItem {
  text-align: right;
  padding-right: 10px;
}
.lastHeading {
  padding-right: 10px;
  text-align: right;
}

@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 991px) {
  .tableWrapper {
    max-height: 500px;
  }
}
@media only screen and (max-width: 520px) {
}
