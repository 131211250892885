.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.outlet {
  max-width: 366px;
  margin: auto;
  max-width: 366px;
  width: 90%;
  padding-top: 120px;
}
