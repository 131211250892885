.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: auto;
}
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px 15px;
}
.header2 {
  display: flex;
  flex-direction: column;
}

.steps {
  display: flex;
  align-items: center;
  gap: 4px;
}
.step {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--Bg-Light-2);
  cursor: pointer;
}
.activeStep {
  background: var(--Primary-Default);
}
.backButton {
  color: var(--Text-Teritary);
  gap: 4px;
  margin-right: auto;
  padding: 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
}
.padding {
  border-radius: 16px;
  padding: 24px;
  border: 1px solid var(--Br-Dark);
}
.bottomBarContainer {
  padding-bottom: 84px;
  position: relative;
}
.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 8px 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--Bg-Default);
  border-radius: 0 0 16px 16px;
}
.amountContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.bottombarAmountContainer {
  display: flex;
  flex-direction: column;

  padding: 14px 0;
  border-top: 1px solid var(--Br-Dark);
  border-bottom: 1px solid var(--Br-Dark);
}
.amountWrapper {
  border-radius: 0;
  position: static;
}
.learnMore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.infoIcon {
  color: var(--Primary-Default);
}
@media only screen and (max-width: 399px) {
  .padding {
    padding: 12px;
  }
}
