.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 100px 0;
}
.infoContainer {
  max-width: 506px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.img {
  max-width: 336px;
  margin: 0 auto;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 60px 0;
  }
  .img {
    max-width: 270px;
  }
}
@media only screen and (max-width: 520px) {
  .img {
    max-width: 230px;
  }
}
