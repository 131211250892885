.wrapper {
  position: relative;
}
.dateInput {
  width: 100%;
}

.customInput {
  max-width: 300px;
  padding: 12px;
  font-size: 18px;

  background: var(--Bg-Light);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.customInput input {
  color: var(--Text-Secondary);
  font-size: 18px;
}
.customInput input::placeholder {
  color: var(--Text-Teritary);
}

.icon {
  cursor: pointer;
  max-width: 24px;
  width: 100%;
}
