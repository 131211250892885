.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  padding: 10px 0;
  border-bottom: 1px solid var(--Bg-Dark);
  background: var(--Bg-Default);
}

.wrapperBg {
  background: var(--Bg-Default);
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.2);
}

.header {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 0;
}

.logo {
  max-width: 105px;
  width: 100%;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navItem {
  color: var(--Base-0);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.tabsAndButton {
  display: grid;
  grid-template-columns: 1fr 250px;
  gap: 15px;
}
.tabs {
  justify-content: center;
  margin: 0 auto;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.buttonContainer .button {
  gap: 5px;
}

.iconContainer {
  width: 44px;
  height: 44px;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Bg-Light);
  cursor: pointer;
}

.activeIconContainer {
  background: var(--Primary-Default);
}

.icon {
  max-width: 24px;
  width: 100%;
}

.openIcon,
.closeIcon {
  display: none;
  margin-left: auto;
}

.logo2 {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .header {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .tabsAndButton {
    min-height: 100vh;
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 80px;

    width: 100%;
    min-height: 100vh;
    background: var(--Bg-Default);
    border-left: 1px solid var(--Br-Dark);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .tabsAndButton .tabs {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    background: transparent;
  }
  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .tabsAndButton .tabs,
  .buttonContainer {
    max-width: 320px;
    margin: 0 auto;
  }

  .openIcon,
  .closeIcon {
    display: block;
    cursor: pointer;
    color: var(--Heading-Primary);
    font-size: 28px;
  }

  .closeIcon {
    position: fixed;
    z-index: 10;
    top: 20px;
    right: 20px;
  }

  .logo2 {
    display: block;
  }

  .tabsContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    grid-template-columns: 1fr auto;
    gap: 18px 15px;
  }

  .openIcon {
    order: 2;
  }

  .tabsContainer {
    order: 3;
    grid-column: span 2;
    justify-content: center;
  }
}

@media only screen and (max-width: 450px) {
}
