.methods {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.method {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  gap: 18px;
  position: relative;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid transparent;
  background: var(--Bg-Light);
  cursor: pointer;
}

.method.active {
  border: 1px solid var(--Primary-Default);
  align-items: start;
}

.logoContainer {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active .logoContainer {
  background: var(--Primary-Default);
}

.logo {
  max-width: 11px;
}

.star {
  max-width: 32px;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  border-bottom-right-radius: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.name {
  padding-bottom: 6px;
}

.feature {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
}

.featureLogo {
  max-width: 12px;
  width: 100%;
  margin-top: 2px;
}

@media only screen and (max-width: 399px) {
  .method {
    padding: 12px;
  }
}