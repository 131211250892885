.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon {
  max-width: 20px;
  width: 100%;
}
.coinsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.coins {
  max-height: 250px;
  overflow-y: auto;
  width: calc(100% + 40px);
  padding: 0 20px;
  transform: translateX(-20px);
}
.coin {
  padding: 10px 14px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  width: 100%;
  border: 1px solid transparent;
}
.activeCoin {
  background: var(--Bg-Light);
  border: 1px solid var(--Primary-Default);
}
.nameAndPrice {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.coinImg {
  max-width: 36px;
  border-radius: 50%;
}
.input {
  border: none;
  background: var(--Bg-Light);
}

.input input::placeholder {
  color: var(--Text-Teritarry);
}
.checkMark {
  color: var(--Primary-Default);
  margin-left: auto;
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 0;
  }
}
@media only screen and (max-width: 520px) {
  .coin {
    gap: 8px;
    cursor: pointer;
    width: 100%;
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 399px) {
  .coins {
    max-height: 250px;
    overflow-y: auto;
    width: calc(100% + 16px);
    padding: 0 8px;
    transform: translateX(-8px);
  }
}
