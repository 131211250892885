.mainWrapper {
  padding: 8px 2px;
  border: 1px solid var(--Br-Dark);
  border-radius: 16px;
}

/* .wrapper {
  max-height: 400px;
  overflow-y: auto;
} */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 16px 22px;
}

.infoContainer {
  border-radius: var(--radius);
  background: rgba(99, 102, 241, 0.1);
  padding: 12px;
}

.button {
  padding: 0px 18px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
  height: 52px;
}

.logo {
  max-width: 10px;
  width: 100%;
}

.qrCodeButton,
.copyButton {
  background: var(--Bg-Light);
  color: var(--Text-Primary);
}

.qrCodeButton {
  width: auto;
  display: flex;
  max-width: 52px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addressAndQrCode {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.selectedItem {
  border-radius: 6px;
  background: #213032;
  color: #24C789;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 110%;
}

.supportedCoin {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 20px;
}

.coinsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.allButton,
.lessButton {
  background: var(--Bg-Light);
  width: 48px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  color: var(--Primary-Default);
  padding: 0;
}

.lessButton {
  width: auto;
  margin-left: auto;
  background: none;
  width: auto;
  max-width: 55px;
  padding: 0;
}

.allCoins {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 5px;
}

.coinLogo {
  max-width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
}

.selected {
  border: 2px solid var(--Primary-Default);
  transform: scale(1.1);
}

.address {
  max-width: 220px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.copyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Bg-Default);
  height: 36px;
  width: 36px;
  border-radius: 8px;
  padding: 8px;
  display: block;
}

.copyContainer .logo {
  max-width: 20px;
}

.or {
  position: relative;

  padding: 0 12px;
  background: var(--Bg-White);
  width: 100%;
}

.or::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 43%;
  height: 1px;
  transform: translateY(-50%);
  background-color: var(--Br-Dark);
}

.or::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 43%;
  height: 1px;
  transform: translateY(-50%);
  background-color: var(--Br-Dark);
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;

  background: var(--Bg-Default);
}

.value {
  margin-left: auto;
  color: var(--Heading-Primary);
}

.amountContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.bottombarAmountContainer {
  display: flex;
  flex-direction: column;

  padding: 14px 0;
  border-top: 1px solid var(--Br-Dark);
  border-bottom: 1px solid var(--Br-Dark);
}

.partial {
  color: var(--Warning);
}

.completed {
  color: var(--Success);
}

.learnMore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.infoIcon {
  color: var(--Primary-Default);
}

@media only screen and (max-width: 520px) {
  .button {
    padding: 0px 12px;
  }
}

@media only screen and (max-width: 399px) {
  .container {
    padding: 12px 11px;
  }
}