.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--Bg-Default);
}

.wrapperBg {
  background: var(--Bg-Default);
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.2);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.logo {
  max-width: 105px;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navItem {
  color: var(--Heading-Primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.navActive {
  font-weight: 700;
  text-shadow: 0.05px 0.05px #fff;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.buttonContainer .button {
  gap: 5px;
}

.icon {
  display: none;
}

.mobileTabs {
  display: none;
}

.iconContainer {
  width: 44px;
  height: 44px;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Bg-Light);
  cursor: pointer;
}

.languageIcon {
  max-width: 24px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .navItems {
    flex-direction: column;
    gap: 24px;
    min-height: 100vh;
    background: var(--Bg-Default);
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: calc(100% + 1px);
    right: 0;
    padding-top: 30px;
    max-width: 320px;
    width: 100%;
    min-height: 100vh;

    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  .sidebar {
    transform: translateX(0);
    right: 0;
  }

  .buttonContainer {
    align-items: center;
    gap: 15px;
  }

  .icon {
    display: block;
    cursor: pointer;
    color: #fff;
    font-size: 28px;
  }

  .mobileTabs {
    display: grid;
  }

  .tabs {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .logo {
    max-width: 100px;
  }

  .buttonContainer .button {
    padding: 8px 12px;
  }
}

@media only screen and (max-width: 450px) {
  .waitingList {
    display: none;
  }
}
