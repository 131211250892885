.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  margin: auto;
  padding: 20px 0;
}
.title {
  color: var(--Gray-500);
}
.img {
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
}
.imgAndTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.details {
  display: flex;
  flex-direction: column;
  background: var(--Bg-Light);
  border-radius: 12px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  padding: 14px 20px;
  border-bottom: 1px solid var(--Br-Dark);
}
.spaceBetween:last-child {
  border: none;
}
.completed {
  color: var(--Success);
}
.button {
  color: var(--Primary-Default);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  display: block;
}
