.mainWrapper {
  display: grid;
  grid-template-columns: 1fr 348px;
  gap: 60px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: auto;
}

.header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 15px;
}

.buttonContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr auto;
  gap: 8px;
}

.input {
  background: var(--Bg-Light);
  max-width: 220px;
}

.downloadButton {
  border-radius: 8px;
  background: var(--Bg-Light);
  padding: 9px 10px;
}

.downloadIcon {
  max-width: 24px;
  width: 100%;
}

.tableWrapper {
  overflow-x: auto;
  max-height: 500px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.heading,
.item {
  white-space: nowrap;
  text-align: left;
}

.table td,
.table th {
  height: 100%;
  border-top: 1px solid var(--Br-Dark);
  padding: 10px 20px;
}

.table th {
  position: sticky;
  top: -1px;
  background: var(--Bg-Default);
}

.table th:first-child,
.table td:first-child {
  padding-left: 0;
}

.table th:last-child,
.table td:last-child {
  padding-right: 12px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dateContainer .item,
.amountContainer .item {
  padding: 0;
  border: none;
}

.firstHeading,
.firstItem {
  padding-left: 10px;
}

.lastHeading {
  padding-right: 10px;
}

.statusContainer {
  padding: 10px 16px;
}

.status {
  padding: 2px 6px;
  border-radius: 4px;
  height: auto;
  width: max-content;
}

.finished {
  background: rgba(36, 199, 136, 0.1);
  color: var(--Success);
}

.expired {
  color: var(--Error);
  background: rgba(242, 65, 65, 0.1);
}

.waiting {
  color: var(--Warning);
  background: rgba(255, 193, 7, 0.1);
}

.input input::placeholder {
  color: var(--Text-Teritary);
}

@media only screen and (max-width: 1199px) {
  .mainWrapper {
    gap: 40px;
  }

  .input {
    max-width: 100%;
  }

  .header {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .mainWrapper {
    gap: 60px;
  }
}