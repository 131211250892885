.modal {
  max-width: 380px;
  max-height: calc(100% - 30px);
  height: max-content;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}
.qrCodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.qrCodeWrapper {
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
/* iOS specific adjustments */
@supports (-webkit-touch-callout: none) {
  .modal {
    height: auto; /* Ensure it adapts correctly */
    max-height: 80vh; /* Limit the maximum height */
  }
}
