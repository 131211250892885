.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  border: 1px solid var(--Br-Dark);
  border-radius: 16px;
  padding: 24px;
}

.slider {
  position: relative;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Add this to ensure seamless sliding effect */
.sliderImages {
  display: flex;

  width: calc(100% * 3); /* Adjust based on the number of images */
}

.sliderImages img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.sliderDots {
  width: 100%;
  text-align: center;
}

.sliderDots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  background-color: var(--Bg-Light-2);
  border-radius: 50%;
  transition: background-color 0.3s ease, width 1s ease;
}

.sliderDots .dot.active {
  background-color: var(--Primary-Default);
  border-radius: 8px;
  width: 20px; /* Increased width for the active dot */
}
