.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}
.selectContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 24px;
}
.label {
}
.payCurrency.label {
  margin-top: 24px;
  height: 100%;
}
.type.label {
  height: 100%;
}
.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.info {
  padding: 4px 8px;
  border-radius: 6px;
  background: var(--Bg-Light-2);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.img {
  max-width: 22px;
  width: 100%;
  border-radius: 50%;
}

.price {
  margin-left: auto;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px;
}
.input {
  background: var(--Border-Light);
  border: 1px solid var(--Br-Dark-2);
}
.input input {
  font-size: 16px;
  font-weight: 500;
  color: var(--Heading-Primary);
}
.priceContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  border: 1px solid var(--Br-Dark-2);
  padding: 14px 16px;
  border-radius: var(--radius);
}
.priceInput {
  border: none;
  padding: 0;
}
.typeContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.fixedRate,
.freePaid {
  display: flex;
  align-items: center;
}
.infoIcon {
  font-size: 16px;
  color: var(--Text-Placeholder);
  margin-top: 4px;
  margin-left: 8px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
  .selectContainer {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .addressContainer {
    grid-template-columns: 1fr;
  }
  .generateAddressContainer {
    padding: 12px;
  }
  .amountInput input {
    font-size: 20px;
  }

  .qrCodeContainer {
    align-items: center;
  }
}
