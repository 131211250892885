.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.infoContainer {
  border-radius: var(--radius);
  background: rgba(99, 102, 241, 0.1);
  padding: 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}
.infoIcon {
  color: var(--Primary-Default);
  margin-top: 2px;
  font-size: 20px;
}
.icon {
  max-width: 20px;
  width: 100%;
}
.networksWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.networks {
  max-height: 250px;
  overflow-y: auto;
  width: calc(100% + 40px);
  padding: 0 20px;
  transform: translateX(-20px);
}
.network {
  padding: 10px 14px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  width: 100%;
  border: 1px solid transparent;
}
.activeNetwork {
  background: var(--Bg-Light);
  border: 1px solid var(--Primary-Default);
}
.nameAndPrice {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.networkImg {
  max-width: 36px;
  border-radius: 50%;
}
.input {
  border: none;
  background: var(--Bg-Light);
}

.input input::placeholder {
  color: var(--Text-Teritarry);
}
.checkMark {
  color: var(--Primary-Default);
  margin-left: auto;
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 0;
  }
}
@media only screen and (max-width: 520px) {
  .network {
    gap: 8px;
    cursor: pointer;
    width: 100%;
    border: 1px solid transparent;
  }
}
@media only screen and (max-width: 399px) {
  .networks {
    max-height: 250px;
    overflow-y: auto;
    width: calc(100% + 16px);
    padding: 0 8px;
    transform: translateX(-8px);
  }
}
