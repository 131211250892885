.dropdown {
  position: relative;
}

.dropdownMain {
  max-width: 366px;
  width: max-content;
  height: 0;
  overflow: hidden;
  background-color: var(--Bg-Default);
  border: 1px solid var(--Br-Dark);

  border-radius: 10px;
  position: absolute;
  left: 0%;
  transform: translateX(calc(-100% + 48px));
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.4s ease;
  color: var(--Text-Primary);
  padding-bottom: 16px;
}

.dropdownMain.active {
  /* overflow: auto; */
  max-height: 280px;
  height: max-content;
  opacity: 1;
  pointer-events: all;
}

.userInfo {
  padding: 16px 16px;
  border-bottom: 1px solid var(--Br-Dark);
}
.list {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.item {
  padding: 5px 16px;
}
.logout {
  border-top: 1px solid var(--Br-Dark);
  padding-top: 16px;
}
