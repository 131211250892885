.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.buttonContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.button {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeButton {
  background: var(--Bg-Light);
}
.icon {
  max-width: 20px;
  width: 100%;
}
.merchant {
  padding: 14px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  width: 100%;
}
.activeMerchant {
  background: var(--Bg-Light);
}

.delete {
  margin-left: auto;
}
.closeIcon {
  font-size: 16px;
  color: var(--Text-Placeholder);
}
.merchantImgContainer {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Bg-Light);
  border-radius: 8px;
}
.activeMerchant .merchantImgContainer {
  background: var(--Bg-Default);
}
.merchantImg {
  max-width: 24px;
}
.input {
  border: none;
  background: var(--Bg-Light);
}
.addMerchantContainer {
  border-radius: 12px;
  border: var(--stroke-0, 1px) solid
    var(--utilities-transparent-8, rgba(255, 255, 255, 0.08));
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.addMerchantButton {
}
