.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  padding: 10px 0;
  border-bottom: 1px solid var(--Bg-Dark);
  background: var(--Bg-Default);
}

.wrapperBg {
  background: var(--Bg-Default);
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.2);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 0;
}

.logo {
  max-width: 105px;
  width: 100%;
}

.walletButton {
  margin-right: 10px;

  border-radius: 0;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 18px 15px;
  }

  .walletButton {
    order: 2;
  }

  .heading {
    text-align: center;
    display: flex;
    order: 3;
    grid-column: span 2;
    justify-content: center;
  }
}