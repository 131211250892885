.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0;
  border-top: 1px solid var(--Br-Dark);
  border-bottom: 1px solid var(--Br-Dark);
}

.box {
  background: var(--Bg-Light);
  border-radius: var(--radius-20, 20px);

  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.estimatedProfit {
  background: url(../../../images/wallet/pattern.png), var(--Bg-Light);
  object-fit: cover;
  gap: 20px;
}
.topHeader {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.copyAddress {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 28px;
  border: 1px solid var(--Br-Dark-2);
  padding: 4px 12px;
  padding-right: 4px;
  border-radius: var(--radius);
}
.address {
  word-break: break-all;
}
.copyIcon {
  max-width: 20px;
  width: 100%;
}
.copyContainer {
  width: 36px;
  height: 36px;
  background: var(--Primitive-950);
  border-radius: 8px;
}
.referContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}
.referHeading {
  padding-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
  }
}
