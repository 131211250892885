.wrapper {
  display: grid;
  grid-template-columns: 348px 1fr;
  gap: 60px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    gap: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
