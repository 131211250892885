.wrapper {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 100px 0;
  padding-bottom: 0;
}
.heading {
  max-width: 540px;
}
.container {
  display: grid;
  grid-template-columns: 1fr 328px;
  gap: 48px;
  align-items: center;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.input {
  background: var(--Bg-Light);
}
.input input::placeholder {
  color: var(--Text-Placeholder);
  opacity: 1;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.message {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  padding: 12px 16px;
  border-radius: 10px;

  background: var(--Bg-Light);
  color: var(--Text-Primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
  outline: none;
  border: none;
}
.button {
  margin-top: auto;
}

.title {
  padding-bottom: 12px;
}
.item {
  color: var(--Primitive-300);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    gap: 36px;
    padding: 60px 0;
    padding-bottom: 0;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .inputWrapper {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 520px) {
  .inputContainer {
    grid-template-columns: 1fr;
  }
}
