.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 55px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.buttonContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.button {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeButton {
  background: var(--Bg-Light);
}
.icon {
  max-width: 20px;
  width: 100%;
}
.coins {
  max-height: 500px;
  overflow-y: auto;
}
.coin {
  padding: 10px 14px;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  width: 100%;
}
.activeCoin {
  background: var(--Bg-Light);
}
.priceContainer {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.closeIcon {
  font-size: 16px;
  color: var(--Text-Placeholder);
}

.coinImg {
  max-width: 36px;
  border-radius: 50%;
}
.input {
  border: none;
  background: var(--Bg-Light);
}
.addCoinContainer {
  border-radius: 12px;
  border: var(--stroke-0, 1px) solid
    var(--utilities-transparent-8, rgba(255, 255, 255, 0.08));
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.input input::placeholder {
  color: var(--Text-Teritarry);
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 0;
  }
}
