.wrapper {
  padding: 4px 28px;
  border: 1px solid var(--Br-Dark);
  border-radius: 16px;
  min-height: 218px;
}
.header {
  margin: auto;
  height: 100%;
  max-width: 353px;
  width: 100%;
}
.infoContainer {
  padding: 24px 0;
  display: grid;
  grid-template-columns: 240px 1fr minmax(150px, auto);
  gap: 24px 15px;
  align-items: center;
  border-bottom: 1px solid var(--Br-Dark);
}
.infoContainer:last-child {
  border: none;
}
.keyContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.key {
  word-break: break-all;
}
.editAndDelete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.copyIcon {
  max-width: 20px;
  width: 100%;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.button {
  padding: 10px 14px;
  height: 50px;
}
.editButton,
.deleteButton {
  padding: 10px 14px;
}
@media only screen and (max-width: 991px) {
  .infoContainer {
    grid-template-columns: 1fr minmax(150px, auto);
  }
  .name {
    grid-column: 1/-1;
  }
}
@media only screen and (max-width: 767px) {
  .infoContainer {
    grid-template-columns: 1fr;
  }
  .buttonContainer {
    flex-direction: column;
  }
  .buttonContainer .button {
    width: 100%;
  }
}
