.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}
.details {
  display: flex;
  flex-direction: column;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.spaceBetween:last-child {
  border: none;
}
.value {
  margin-left: auto;
  word-break: break-all;
}
.completed {
  background: rgba(36, 199, 136, 0.1);
  color: var(--Success);
  padding: 2px 6px;
  border-radius: 4px;
}
.pending {
  background: rgba(255, 183, 41, 0.1);
  color: var(--Warning);
  padding: 2px 6px;
  border-radius: 4px;
}
.failed {
  color: var(--Error);
  background: rgba(242, 65, 65, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
}
