.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
}
.verificationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.input {
  background: transparent;
  max-width: 366px;
  margin: 0 auto;
  border: 1px solid var(--Br-Dark-2);
}
.input input::placeholder {
  color: var(--Text-Teritarry);
}
.phoneNumber {
  display: flex;
  align-items: center;
  gap: 12px;
}
.code {
  color: var(--Text-Primary);
  font-size: 18px;
}
.dropdownItem {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: var(--Text-Primary);
  border: 1px solid var(--Br-Dark-2);
  height: 100%;
  border-radius: var(--radius);
  padding: 14px 20px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
  .selectContainer {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .addressContainer {
    grid-template-columns: 1fr;
  }

  .qrCodeContainer {
    align-items: center;
  }
}
