.inputs {
  border: 1px solid var(--Br-Dark);
  border-radius: var(--radius);
  background-color: var(--Bg-Default);
}
.noResendInputs {
  background: var(--Bg-Light);
  border: 1px solid var(--Br-Dark-2);
}

.input {
  padding: 18px;
  text-align: center;
  font-size: 24px;
  outline: none;
  color: var(--Text-Secondary);
}

.inputs > * {
  justify-content: center;
  gap: 0;
}

.input {
  width: 60px !important;
  height: 56px !important;

  border-right: 1px solid var(--Br-Dark);
  margin-right: 0 !important;
}
.noResendInputs .input {
  border-right: 1px solid var(--Br-Dark-2);
}
.input:last-child {
  border: none;
}
@media screen and (max-width: 424px) {
  .input {
    width: 50px !important;
    height: 50px !important;
  }
}

@media screen and (max-width: 360px) {
  .input {
    width: 40px !important;
    height: 40px !important;
  }
}

.hasError {
  border-color: var(--Error);
}

.helperError {
  color: var(--Error);
  font-size: 16px;
}

/* .inputs */
input::placeholder {
  color: var(--Text-Placeholder);
  opacity: 0.5;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
}
.noResend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  padding-top: 16px;
}

.actions:not(.fullWidth) > * {
  justify-content: center;
  align-items: center;
  display: flex;
}

.actions.fullWidth > * {
  width: 100%;
  justify-content: center;
}

.resend {
  background-color: transparent;
  color: var(--Text-Secondary);
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.resend:disabled {
  opacity: 0.5;
  pointer-events: none;
}
