.dropdown {
  position: relative;
  max-width: 300px;
  width: 100%;
  background: var(--Bg-Light);
  border-radius: 10px;
  padding: 12px 10px;
}
.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.arrow {
  font-size: 14px;
  color: var(--Text-Teritary);
  cursor: pointer;
  margin-left: auto;
}
.dropdownMain {
  min-width: calc(100% + 5px);
  width: max-content;
  position: absolute;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
  border: 1px solid var(--Br-Dark);
  border-radius: var(--radius);
  background: var(--Bg-Light);
}

.list {
  height: 0;
  overflow: hidden;
}

.dropdownMain.active {
  opacity: 1;
  pointer-events: all;
}
.active .list {
  overflow: auto;
  max-height: 250px;
  height: 100%;
  opacity: 1;
  pointer-events: all;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.4s ease;
  color: var(--Heading-Primary);
  font-size: 16px;
  font-weight: 500;
}

.listItem.active {
  background: var(--Primary-Default);
}
.listItem.active {
  color: var(--Heading-Primary);
}
.listItem:hover {
  background: var(--Primary-Default);
  color: var(--Heading-Primary);
}
.searchContainer {
  position: relative;
  padding: 2px 12px;
  border-bottom: 1px solid var(--Br-Dark);
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchContainer .searchIcon {
  font-size: 20px;
  color: var(--Text-Placeholder);
  margin-top: 2px;
}

.searchContainer input {
  height: 40px;
  border-radius: 10px;

  font-size: 16px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;

  color: var(--Text-Primary);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--Text-Placeholder);
}
