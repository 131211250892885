.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}
.selectContainer {
  display: grid;
  grid-template-columns: auto 1fr;

  gap: 24px;
}
.label {
  margin-top: 12px;
}
.networkContainer,
.amountInputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.info {
  padding: 4px 8px;
  border-radius: 6px;
  background: var(--Bg-Light-2);
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.fee {
  color: var(--Heading-Primary);
}
.wallet {
  font-weight: 600;
  font-size: 14px;
  color: var(--Heading-Primary);
}
.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px;
}
.input {
  background: var(--Border-Light);
  border: 1px solid var(--Br-Dark-2);
}
.amountInput {
  height: 56px;
}
.amountInput input {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: var(--Heading-Primary);
}
.qrCodeContainer {
  width: 48px;
  height: 48px;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Primitive-800);
}
.qrCode {
  max-width: 24px;
  width: 100%;
}
.bottombar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.receiveContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
  .selectContainer {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .addressContainer {
    grid-template-columns: 1fr;
  }
  .generateAddressContainer {
    padding: 12px;
  }
  .amountInput input {
    font-size: 20px;
  }

  .qrCodeContainer {
    align-items: center;
  }
}
