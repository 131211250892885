.wrapper {
  padding: 4px 28px;
  border: 1px solid var(--Br-Dark);
  border-radius: 16px;
}
.infoContainer {
  padding: 24px 0;
  display: grid;
  grid-template-columns: 240px 1fr minmax(125px, auto);
  gap: 24px 15px;
  align-items: center;
  border-bottom: 1px solid var(--Br-Dark);
}
.infoContainer:last-child {
  border: none;
}
.input {
  max-width: 300px;
  padding: 12px;

  background: var(--Bg-Light);
  border: none;
}
.passwordInput {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.passwordInput img {
  display: none;
}
.info {
  padding: 12px 12px;
  border: 1px solid transparent;
}
.input input {
  color: var(--Heading-Primary);
  font-size: 18px;
}
.input input::placeholder {
  color: var(--Text-Teritary);
}

.button {
  padding: 10px 14px;
  height: 50px;
}
@media only screen and (max-width: 991px) {
  .infoContainer {
    grid-template-columns: 1fr minmax(125px, auto);
  }
  .label {
    grid-column: 1/-1;
  }
}
@media only screen and (max-width: 520px) {
  .infoContainer {
    grid-template-columns: 1fr;
  }
}
