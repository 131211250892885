.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 44px 0;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}

.featured {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  border-radius: 16px;
  background: var(--Bg-Light);
  padding: 28px;
}
.featured .logo {
  max-width: 88px;
  width: 100%;
}
.featured:first-child .logo {
  max-width: 160px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.button {
  margin-top: auto;
}
.arrow {
  margin-top: 0;
  font-size: 20px;
  color: var(--Heading-Primary);
}
@media only screen and (max-width: 1199px) {
  .featured {
    padding: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    grid-template-columns: 1fr;
  }
}
