.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 8px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}

.container {
  max-width: 366px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.controlContainer {
  padding: 20px;
  border: 1px solid var(--Br-Dark-2);
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 266px;
  overflow-y: auto;
}
.controlHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--Br-Dark-2);
}
.fullAccess {
  display: flex;
  align-items: center;
  gap: 8px;
}
.others {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.access {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
}
@media only screen and (max-width: 380px) {
  .controlContainer {
    padding: 16px;
  }
}
