.dropdown {
  position: relative;
}

.dropdownMain {
  max-width: 320px;
  width: max-content;
  height: 0;
  overflow: hidden;
  background-color: var(--Bg-Default);
  border: 1px solid var(--Br-Dark);

  border-radius: 10px;
  position: absolute;
  left: 0%;
  transform: translateX(-41%);
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.4s ease;
  color: var(--Text-Primary);
  padding-bottom: 16px;
}

.dropdownMain.active {
  /* overflow: auto; */
  max-height: 350px;
  height: max-content;
  opacity: 1;
  pointer-events: all;
}
.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  padding: 16px 16px;
  border-bottom: 1px solid var(--Br-Dark);
}
.inbox {
  margin-left: auto;
  cursor: pointer;
}
.inboxIcon {
  max-width: 22px;
  width: 100%;
}
.allNotifications {
  overflow-y: auto;
}
.notifications {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid var(--Br-Dark);
  padding: 12px 16px;
}
.notifications:last-child {
  border: none;
  padding-bottom: 0;
}
.notification {
  display: grid;
  grid-template-columns: 28px 1fr;
  gap: 10px;
}
.notificationHeaderAndInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.notificationHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}
.icon {
  max-width: 24px;
  width: 100%;
}
.moreInfo {
  display: flex;
  flex-direction: column;
}
.moreButton {
  color: var(--Primary-Default);
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
@media only screen and (max-width: 991px) {
  .dropdownMain {
    max-width: 300px;

    transform: translateX(-19%);
  }
}
