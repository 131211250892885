.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 283px;
}
.img {
  max-width: 96px;
  width: 100%;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 32px;
  }
}
