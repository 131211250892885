.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.infoContainer {
  border-radius: var(--radius);
  background: rgba(99, 102, 241, 0.1);
  padding: 12px;
}
