.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 348px;
  gap: 60px;
}
.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 32px;
  width: 100%;
  border-bottom: 1px solid var(--Br-Dark);
}
.tab {
  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;
  padding-bottom: 16px;
}
.activeTab {
  color: var(--Heading-Primary);
  position: relative;
}
.activeTab::before {
  content: "";
  position: absolute;
  bottom: 0%;
  transform: translateY(50%);
  width: 100%;
  height: 2px;
  background: var(--Primary-Default);
  z-index: 1;
}
.name {
  color: var(--Text-Secondary);
  white-space: nowrap;
}
.icon {
  max-width: 22px;
  width: 100%;
}
.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    gap: 40px;
  }
  .container {
    gap: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    gap: 60px;
  }
  .container {
    grid-template-columns: 1fr;
    gap: 60px;
  }
}
@media only screen and (max-width: 520px) {
  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 0;
    border: none;
  }
  .tab {
    justify-content: center;
    border-bottom: 1px solid var(--Br-Dark);
    padding: 0 8px;
    padding-bottom: 16px;
  }
  .tab:last-child {
    grid-column: 1/-1;
  }
  .name {
    text-align: center;
  }
  .icon {
    display: none;
  }
}
