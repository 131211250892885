.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: auto;
}

.info {
  line-height: 155%;
}
