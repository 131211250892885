.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 16px;
}

.closeButton {
  margin-left: auto;
  color: var(--Text-Teritarry);
  font-size: 20px;
}
.closeIcon {
  color: var(--Text-Teritary);
}
.amountWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.amountContainer {
  padding: 20px;
  background: var(--Bg-Default);
  border-radius: 12px;
  border: 1px solid var(--Base-200);
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
}
.inputContanier,
.balanceAndWallet {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.input {
  border: 0;
  padding: 0;
}
.input input {
  font-size: 36px;
}
.convertButton {
  background: var(--Primary-Default);
  border-radius: 50%;
  border: 4px solid var(--Primitive-900);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
}
.convertButton:hover {
  transform: translate(-50%, 50%);
}
.convertIcon {
  font-size: 24px;
  color: var(--Heading-Primary);
}
.rateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rate {
  margin-left: auto;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding-top: 16px;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
}
