.container {
  padding-top: 120px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 60px;
}
.outlet {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media only screen and (max-width: 1199px) {
  .container {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .heading {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding-top: 100px;
  }
}
