.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.outlet {
  margin: auto;
  max-width: 384px;
  width: 90%;
  padding-top: 100px;
}
@media only screen and (max-width: 767px) {
  .outlet {
    padding-top: 150px;
  }
}
