.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.img {
  max-width: 96px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
